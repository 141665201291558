import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input"; // Importing mui-tel-input

const categories = ["Category 1", "Category 2", "Category 3", "Category 4"];
const countries = ["India", "USA", "UK", "Canada"];

const RegistrationSettings = () => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [phone, setPhone] = useState(""); // State for phone number
  const [country, setCountry] = useState("India"); // Default country selection
  const [maxCategories, setMaxCategories] = useState(10); // State for max category selection

  // Handle category selection
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= maxCategories) {
      setSelectedCategories(value);
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submit logic
    console.log({
      selectedCategories,
      startDate,
      endDate,
      phone,
      country,
      maxCategories,
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Registration Settings
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={formDisabled}
                onChange={() => setFormDisabled(!formDisabled)}
              />
            }
            label="Disable Form"
          />
        </FormGroup>

        {/* Start Date */}
        <TextField
          fullWidth
          size="small"
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={formDisabled}
          sx={{ my: 2 }}
        />

        {/* End Date */}
        <TextField
          fullWidth
          size="small"
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={formDisabled}
          sx={{ my: 2 }}
        />

        {/* Max Categories Allowed */}
        <TextField
          fullWidth
          size="small"
          label="Max Categories selection allowed"
          type="number"
          value={maxCategories}
          onChange={(e) => setMaxCategories(Number(e.target.value))}
          disabled={formDisabled}
          sx={{ my: 2 }}
        />
        {/* Phone Number with Default Country */}
        <MuiTelInput
          fullWidth
          size="small"
          label="Phone Number"
          value={phone}
          onChange={(newPhone) => setPhone(newPhone)}
          defaultCountry="IN" // Default country set to India
          disabled={formDisabled}
          sx={{ my: 2 }}
        />

        {/* Default Country Selection */}
        <FormControl
          fullWidth
          sx={{ my: 2 }}
          size="small"
          disabled={formDisabled}
        >
          <InputLabel>Country</InputLabel>
          <Select
            label="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          size="small"
          disabled={formDisabled}
        >
          Save Settings
        </Button>
      </form>
    </Container>
  );
};

export default RegistrationSettings;
