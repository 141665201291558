import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

// World Map TopoJSON URL
const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

// Example countries and their approximate coordinates
const countryCoordinates = {
  India: [78.9629, 20.5937],
  USA: [-95.7129, 37.0902],
  China: [104.1954, 35.8617],
  Germany: [10.4515, 51.1657],
};

// Scale for marker size based on the count value
const markerScale = scaleLinear().domain([0, 100]).range([5, 20]);

const WorldMapChart = ({ data }) => {
  const [zoom, setZoom] = useState(1);

  const handleZoomIn = () => {
    if (zoom < 5) setZoom(zoom + 0.5);
  };

  const handleZoomOut = () => {
    if (zoom > 1) setZoom(zoom - 0.5);
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Zoom buttons using MUI IconButton */}
      <div style={{ position: "absolute", top: 10, right: 10, zIndex: 1000 }}>
        <IconButton
          onClick={handleZoomIn}
          color="primary"
          aria-label="zoom in"
          style={{ margin: "5px" }}
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          onClick={handleZoomOut}
          color="primary"
          aria-label="zoom out"
          style={{ margin: "5px" }}
        >
          <ZoomOutIcon />
        </IconButton>
      </div>

      <ComposableMap
        projectionConfig={{ scale: 150 }}
        style={{ width: "100%", height: "246px" }}
      >
        <ZoomableGroup zoom={zoom} minZoom={1} maxZoom={5}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#EAEAEC"
                  stroke="#D6D6DA"
                />
              ))
            }
          </Geographies>

          {data.map(({ country, count }) => {
            const coordinates = countryCoordinates[country];
            if (!coordinates) return null;

            return (
              <Marker key={country} coordinates={coordinates}>
                <circle
                  r={markerScale(count)}
                  fill="#FF5722"
                  stroke="#FF5722"
                  strokeWidth={2}
                />
                <text
                  textAnchor="middle"
                  y={markerScale(count) + 10}
                  style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                >
                  {country} ({count})
                </text>
              </Marker>
            );
          })}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default WorldMapChart;
