export const TEXT = "TEXT";
export const SELECT = "SELECT";
export const PHONE = "PHONE";
export const DATEPICKER = "DATEPICKER";
export const TIMEPICKER = "TIMEPICKER";
export const TEAM_MEMBER_LIST = "TEAM_MEMBER_LIST";
export const LABELLED_INPUT = "LABELLED_INPUT";
export const LABEL_TEXT = "LABEL_TEXT";
export const DIVIDER = "DIVIDER";
export const DYNAMIC_TABLE = "DYNAMIC_TABLE";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const TEL = "TEL";
export const SOCIAL = "SOCIAL";
export const COUNTRY = "COUNTRY";
export const STATE = "STATE";
export const RADIO_SELECT = "RADIO_SELECT";
export const DESCRIPTION = "DESCRIPTION";
export const CITY = "CITY";
export const CHECKBOX = "CHECKBOX";
export const MULTICHECKBOX = "MULTICHECKBOX";
export const MULTISELECT = "MULTISELECT";
export const PRODUCT_CATEGORY = "PRODUCT_CATEGORY";
