import React from "react";
import {
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
  Stack,
  Box,
} from "@mui/material";
import bgBanner from "../assets/gifs/ExpoBG.gif";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LabelList,
} from "recharts";
import { Link } from "react-router-dom";
import WorldMapChart from "./WorldMapChat";
import * as XLSX from "xlsx";
import { Group } from "@mui/icons-material"; // Example Icon
import FlagIcon from "@mui/icons-material/Flag";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// Visitor registration by month
const dataByMonth = [
  { month: "Jan", visitors: 100 },
  { month: "Feb", visitors: 120 },
  { month: "Mar", visitors: 150 },
  { month: "Apr", visitors: 130 },
  { month: "May", visitors: 170 },
  { month: "Jun", visitors: 160 },
  { month: "Jul", visitors: 140 },
  { month: "Aug", visitors: 180 },
  { month: "Sep", visitors: 190 },
  { month: "Oct", visitors: 200 },
  { month: "Nov", visitors: 210 },
  { month: "Dec", visitors: 220 },
];

// Visitor registration by country
const dataByCountry = [
  { country: "India", visitors: 300 },
  { country: "USA", visitors: 150 },
  { country: "Germany", visitors: 100 },
  { country: "UK", visitors: 120 },
  { country: "China", visitors: 90 },
  { country: "Japan", visitors: 110 },
  { country: "Brazil", visitors: 80 },
  { country: "South Korea", visitors: 70 },
];
const dataByIndustrySector = [
  { industry: "Academia and Technical Institutions", registrations: 50 },
  { industry: "Aerospace / Civil Aviation", registrations: 80 },
  { industry: "Agriculture", registrations: 40 },
  { industry: "Aluminium Extrusion", registrations: 60 },
  {
    industry: "Machine Tools & Other Metal working Industries",
    registrations: 100,
  },
];

// Visitor registration by product category
const dataByCategory = [
  { category: "IMTEX", visitors: 200 },
  { category: "Digital Manufacturing", visitors: 150 },
  { category: "Automation", visitors: 120 },
  { category: "Robotics", visitors: 90 },
  { category: "3D Printing", visitors: 60 },
];

// Visitor registration by designation
const dataByDesignation = [
  { designation: "CEO", visitors: 50 },
  { designation: "Managing Director", visitors: 70 },
  { designation: "Manager", visitors: 120 },
  { designation: "Engineer", visitors: 100 },
  { designation: "Technician", visitors: 60 },
  { designation: "Vice President", visitors: 40 },
  { designation: "General Manager", visitors: 80 },
  { designation: "Executive", visitors: 90 },
  { designation: "Trainee", visitors: 30 },
  { designation: "Consultant", visitors: 20 },
];
const wdata = [
  { country: "India", count: 30 },
  { country: "USA", count: 20 },
  { country: "China", count: 25 },
  { country: "Germany", count: 15 },
];

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const Dashboard = () => {
  const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent
                  sx={{
                    backgroundImage: `url(${bgBanner})`,
                    backgroundSize: "cover",
                    height: 192,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundRepeat: "no-repeat",
                    color: "white",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Welcome!
                  </Typography>
                  <Typography fontSize={17} variant="body1">
                    ExpoPlanner Manage Admin
                  </Typography>
                  <Typography mt={2} variant="body2">
                    Manage Exhibitors, Visitors, Regisrations, OES, Floor Plan
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 192,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Exhibitors
                  </Typography>
                  <Typography variant="h2" component="div" color="primary">
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 192,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Visitors
                  </Typography>
                  <Typography variant="h2" component="div" color="primary">
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#f5f5f5", // Light gray for Team Members
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Group sx={{ fontSize: 40, color: "primary.main" }} />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Team Members
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#e0f7fa", // Light blue for Countries
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <FlagIcon sx={{ fontSize: 40, color: "primary.main" }} />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Countries
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#fff9c4", // Light yellow for Active Exhibitors
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <AirplanemodeActiveIcon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Active Exhibitors
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#ffebee", // Light red for Inactive Exhibitors
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <AirplanemodeInactiveIcon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Inactive Exhibitors
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ height: 304 }}>
            <CardContent>
              <Typography mt={2} variant="h6" textAlign={"center"}>
                Exhibitor Profile Completion
              </Typography>
              <Typography mt={2} variant="h3" textAlign={"center"}>
                50%
              </Typography>
              <Typography mt={2} variant="body1" textAlign={"center"}>
                101 exhibitors have not completed their profile{" "}
                <Link to={""}>notify them</Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid mt={2} item xs={12}>
          <Grid container spacing={2}>
            {/* Visitor Registrations by Year and Month */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack direction={"row"}>
                    <Typography flex={1} variant="h6">
                      Registrations by Month
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={() =>
                        exportToExcel(dataByMonth, "Registrations_by_Month")
                      }
                    >
                      Export
                    </Button>
                  </Stack>
                  <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={dataByMonth}>
                      <XAxis dataKey="month" />
                      <YAxis
                        domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="visitors" fill="#8884d8">
                        <LabelList dataKey="visitors" position="top" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>

            {/* Visitor Registrations by Designations */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack direction={"row"}>
                    <Typography flex={1} variant="h6">
                      Distributions by country
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={() =>
                        exportToExcel(wdata, "Country_Distribution")
                      }
                    >
                      Export
                    </Button>
                  </Stack>

                  <WorldMapChart data={wdata} />
                </CardContent>
              </Card>
            </Grid>

            {/* Visitor Registrations by Countries */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack direction={"row"}>
                    <Typography flex={1} variant="h6">
                      Registrations by Country
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={() =>
                        exportToExcel(dataByCountry, "Registrations_by_Country")
                      }
                    >
                      Export
                    </Button>
                  </Stack>

                  <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={dataByCountry}>
                      <XAxis dataKey="country" />
                      <YAxis
                        domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="visitors" fill="#82ca9d">
                        <LabelList dataKey="visitors" position="top" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>

            {/* Visitor Registrations by Product Category */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack direction={"row"}>
                    <Typography flex={1} variant="h6">
                      Registrations by Product Category
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={() =>
                        exportToExcel(
                          dataByCategory,
                          "Registrations_by_Category"
                        )
                      }
                    >
                      Export
                    </Button>
                  </Stack>
                  <ResponsiveContainer width="100%" height={250}>
                    <PieChart>
                      <Pie
                        data={dataByCategory}
                        dataKey="visitors"
                        nameKey="category"
                        outerRadius={80}
                        fill="#8884d8"
                        label
                      >
                        {dataByCategory.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>

            {/* Visitor Registrations by Designations */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack direction={"row"}>
                    <Typography flex={1} variant="h6">
                      Registrations by Designations
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={() =>
                        exportToExcel(
                          dataByDesignation,
                          "Registrations_by_Designation"
                        )
                      }
                    >
                      Export
                    </Button>
                  </Stack>

                  <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={dataByDesignation}>
                      <XAxis dataKey="designation" />
                      <YAxis
                        domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="visitors" fill="#ff7300">
                        <LabelList dataKey="visitors" position="top" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>

            {/* Visitor Registrations by Industry Sectors */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack direction={"row"}>
                    <Typography flex={1} variant="h6">
                      Registrations by Industry Sectors
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={() =>
                        exportToExcel(
                          dataByIndustrySector,
                          "Registrations_by_Industry_Sector"
                        )
                      }
                    >
                      Export
                    </Button>
                  </Stack>
                  <ResponsiveContainer width="100%" height={250}>
                    <BarChart layout="vertical" data={dataByIndustrySector}>
                      <XAxis
                        type="number"
                        domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]}
                      />
                      <YAxis
                        type="category"
                        dataKey="industry"
                        width={250}
                        interval={0}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="registrations" fill="#ff7300">
                        <LabelList dataKey="registrations" position="right" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
