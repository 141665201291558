import { Box, TextField, Typography, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { getHeader, toastError } from "../../utils/util";
import axios from "axios";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import { toast } from "react-toastify";

const AddSupplierItem = ({ onAdded, show, mode = "add", editItem }) => {
  const [supplierName, setSupplierName] = useState("");
  const [address, setAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [emails, setEmails] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === "edit") {
      setSupplierName(editItem.supplierName);
      setAddress(editItem.address);
      setContactPerson(editItem.contactPerson);
      setEmails(editItem.emails?.join(","));
      setPhoneNumber(editItem.phoneNumber);
    } else {
      clearFields();
    }
  }, [mode, show]);
  const handleAddSupplier = async () => {
    if (!supplierName) {
      toast.error("Supplier name required");
    } else if (!address) {
      toast.error("Address required");
    } else if (!contactPerson) {
      toast.error("Contact person required");
    } else if (!emails) {
      toast.error("Emails required");
    } else if (!phoneNumber) {
      toast.error("Phone number required");
    } else {
      try {
        if (mode === "add") {
          const { data } = await axios.post(
            `${API_ENDPOINT}admin/add-supplier`,
            {
              supplierName,
              address,
              contactPerson,
              emails: emails.split(",").map((email) => email.trim()),
              phoneNumber,
              mode: "add",
            },
            getHeader(ADMIN)
          );

          toast.success(data.message);
          onAdded();
        } else {
          const { data } = await axios.post(
            `${API_ENDPOINT}admin/add-supplier`,
            {
              supplierName,
              address,
              contactPerson,
              emails: emails.split(",").map((email) => email.trim()),
              phoneNumber,
              mode: "edit",
              id: editItem?._id,
            },
            getHeader(ADMIN)
          );
          toast.success(data.message);
          onAdded();
        }
      } catch (error) {
        toastError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  function clearFields() {
    setSupplierName("");
    setAddress("");
    setContactPerson("");
    setEmails("");
    setPhoneNumber("");
  }

  return (
    <Box m={3}>
      <Typography variant="h5" mb={2}>
        Add Supplier
      </Typography>
      <TextField
        type="text"
        size="small"
        label="Supplier Name"
        value={supplierName}
        onChange={(e) => setSupplierName(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        type="text"
        size="small"
        label="Address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        type="text"
        size="small"
        label="Contact Person"
        value={contactPerson}
        onChange={(e) => setContactPerson(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        type="email"
        size="small"
        label="Emails"
        helperText="for multiple emails, use comma-seperated"
        value={emails}
        onChange={(e) => setEmails(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        type="number"
        size="small"
        label="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <Stack mt={2} direction={"row"}>
        <Box flex={1}></Box>
        <LoadingButton
          variant="contained"
          onClick={handleAddSupplier}
          sx={{ marginBottom: 2 }}
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddSupplierItem;
