import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export function toastError(error) {
  toast.error(getErrorResponse(error));
}
export function getErrorResponse(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return "An error occurred. Please try again.";
  }
}
export const getDbHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      dbauth:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYiI6ImltdGV4MjAyNSIsImlhdCI6MTcyNjU3MTk1MywiZXhwIjoxNzU4MTI5NTUzfQ.aKdbfdfptzXxKuYofjEDO7o4ckLUn0Q0ILZz_0lZ8Ls", //db: testapp
    },
  };
};
export const saveUser = (role, data) => {
  localStorage.setItem(role, JSON.stringify(data));
};
export const getUser = (role) => {
  return JSON.parse(localStorage.getItem(role));
};
export const deleteUser = (role) => {
  localStorage.removeItem(role);
};
export const isUserLoggedIn = (role) => {
  return localStorage.getItem(role) !== null;
};
export const UserAuth = ({ children, type }) => {
  return isUserLoggedIn(type) ? children : <Navigate to="/login" />;
};
export const formatDateMMMddYYYY = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-GB", options).replace(",", "");
};
export const getHeader = (type) => {
  return {
    headers: {
      "content-type": "application/json",
      token: getUser(type)?.token,
    },
  };
};
