import { TextField, Typography } from "@mui/material";
import React from "react";

const Labelinput = ({ label }) => {
  return (
    <div>
      <Typography variant="body1">{label}</Typography>
      <TextField fullWidth size="small" variant="outlined" />
    </div>
  );
};

export default Labelinput;
