import React, { useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Stack,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
  Autocomplete,
  FormLabel,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import ImageIcon from "@mui/icons-material/Image";
import CameraRollIcon from "@mui/icons-material/CameraRoll";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReactQuill from "react-quill";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { MuiTelInput } from "mui-tel-input";
import MaximizeIcon from "@mui/icons-material/Maximize";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import NotesIcon from "@mui/icons-material/Notes";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RegistrationContainer from "../RegistrationContainer";
import {
  ADD_PRODUCTS,
  CHECKBOX,
  DATEPICKER,
  DESCRIPTION,
  DIVIDER,
  DYNAMIC_TABLE,
  LABELLED_INPUT,
  LABEL_TEXT,
  MULTISELECT,
  PHONE,
  RADIO_SELECT,
  SELECT,
  TEAM_MEMBER_LIST,
  TEXT,
  TIMEPICKER,
} from "../constants";
import SelectDropDown from "../SelectDropDown";
import LogoutButton from "../../widgets/LogoutButton";
import BackBtn from "../../widgets/BackBtn";
import { useParams } from "react-router-dom";
import OesFormPreview from "./OesFormPreview";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import MuPb from "../../widgets/MuPb";
import Editor from "../../quill/Editor";
import TeamMembers from "../widgets/TeamMembers";
import Labelinput from "../widgets/Labelinput";
import { toast } from "react-toastify";
import DynamicRowTable from "../widgets/DynamicRowTable";
import AddHeaders from "./AddHeaders";
import { element } from "prop-types";
const paletteElements = [
  {
    id: "1",
    type: TEXT,
    label: "Text Field",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TextFieldsIcon />,
  },
  {
    id: "2",
    type: SELECT,
    label: "Select Dropdown",
    settings: {
      required: false,
      isAutoSelect: false,
      options: ["Option 1", "Option 2"],
      grid: 12,
    },
    icon: <ArrowDropDownCircleIcon />,
  },
  {
    id: "3",
    type: MULTISELECT,
    label: "Multiselect Dropdown",
    settings: { required: false, options: ["Option 1", "Option 2"], grid: 12 },
    icon: <ArrowDropDownCircleIcon />,
  },
  {
    id: "4",
    type: CHECKBOX,
    label: "Checkbox",
    settings: { required: false, grid: 12 },
    icon: <CheckBoxIcon />,
  },
  {
    id: "8",
    type: PHONE,
    label: "Phone",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <PhoneEnabledIcon />,
  },
  {
    id: "11",
    type: RADIO_SELECT,
    label: "Radio Button",
    settings: { required: false, options: ["Option 1", "Option 2"], grid: 12 },
    icon: <RadioButtonCheckedIcon />,
  },
  {
    id: "9",
    type: DATEPICKER,
    label: "Date Picker",
    settings: { required: false, grid: 12 },
    icon: <EventIcon />,
  },
  {
    id: "10",
    type: TIMEPICKER,
    label: "Time Picker",
    settings: { required: false, grid: 12 },
    icon: <AccessTimeIcon />,
  },
  {
    id: "14",
    type: LABELLED_INPUT,
    label: "Labelled Input",
    settings: { required: false, grid: 12 },
    icon: <LabelImportantIcon />,
  },
  {
    id: "15",
    type: LABEL_TEXT,
    label: "Label/Text",
    settings: {
      required: false,
      grid: 12,
      labelOption: "body1",
      options: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "subtitle1",
        "subtitle2",
        "body1",
        "body2",
        "caption",
        "button",
        "overline",
        "inherit",
      ],
    },
    icon: <NotesIcon />,
  },
  // {
  //   id: "12",
  //   type: "textarea",
  //   label: "Text Area",
  //   settings: { placeholder: "", required: false, grid: 12 },
  //   icon: <TextSnippetIcon />,
  // },
  // {
  //   id: "13",
  //   type: "file",
  //   label: "File Upload",
  //   settings: { required: false, grid: 12 },
  //   icon: <AttachFileIcon />,
  // },
];
const widgetElements = [
  {
    id: "w1",
    type: TEAM_MEMBER_LIST,
    label: "Team Member List",
    settings: {
      placeholder: "",
      required: false,
      grid: 12,
      width: 300,
      height: 200,
    },
    icon: <PeopleAltIcon />,
  },
  {
    id: "w17",
    type: DIVIDER,
    label: "Divider",
    settings: { required: false, grid: 12 },
    icon: <MaximizeIcon />,
  },
  {
    id: "w2",
    type: "image_slider",
    label: "Image Slider",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <CameraRollIcon />,
  },
  {
    id: "w3",
    type: "static_table",
    label: "Static Table",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TableRowsIcon />,
  },
  {
    id: "w4",
    type: DYNAMIC_TABLE,
    label: "Dynamic Table",
    settings: {
      placeholder: "",
      headers: [
        { label: "Header1", key: "key1" },
        { label: "Header2", key: "key2" },
      ],
      required: false,
      grid: 12,
    },
    icon: <TableRowsIcon />,
  },
  {
    id: "w5",
    type: ADD_PRODUCTS,
    label: "Add Products/Services",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <AddShoppingCartIcon />,
  },
];

const OesFormBuilder = () => {
  const { id } = useParams();
  const [reloadItems, setReloadItems] = useState(false);
  const quillRef = useRef();
  const {
    data: formItem,
    isLoading,
    isError,
    error,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-form-item?id=${id}&reload=${reloadItems}`
  );
  const [formElements, setFormElements] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isSection, setIsSection] = useState(false);
  const [currentEditElement, setCurrentEditElement] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [myTab, setMyTab] = useState(0);
  const [value, setValue] = useState("");

  if (isLoading) {
    return <MuPb />;
  }

  const handleTabChange = (event, newValue) => {
    setMyTab(newValue);
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const finalA = paletteElements.concat(widgetElements);
    if (
      result.source.droppableId !== "form-elements" &&
      result.destination.droppableId === "form-elements"
    ) {
      const element = finalA.find((el) => el.id === result.draggableId);

      if (element) {
        const items = Array.from(formElements);
        let elm = { ...element, id: `${element.id}-${Date.now()}` };
        items.splice(result.destination.index, 0, elm);
        setFormElements(items);
      }
    }
    if (
      result.source.droppableId === "form-elements" &&
      result.destination.droppableId === "form-elements"
    ) {
      const items = Array.from(formElements);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setFormElements(items);
    }
  };

  const handleEditClick = (element) => {
    setCurrentEditElement(element);
    setEditDialogOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, [name]: value },
    });
  };

  const handleEditSave = () => {
    setFormElements((prev) =>
      prev.map((el) =>
        el.id === currentEditElement.id ? currentEditElement : el
      )
    );
    setEditDialogOpen(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, [name]: checked },
    });
  };

  const handleOptionsChange = (e) => {
    const { value } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, options: value.split("\n") },
    });
  };
  const handleNameValueChange = (name, value) => {
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, [name]: value },
    });
  };
  const handleDelete = () => {
    const items = Array.from(formElements);
    const item = items.find((item) => item.id === currentEditElement.id);
    const index = items.indexOf(item);
    if (index !== -1) {
      items.splice(index, 1);
    }
    setFormElements(items);
    setEditDialogOpen(false);
  };
  const formTypes = [
    "General Form",
    "Exhibitor Badges",
    "Tools/Equipments shopping",
    "Interpretors/Translators",
    "Advertisement/Show daily items",
    "Daily premium service items",
  ];
  return (
    <Box m={"auto"} maxWidth={1200}>
      <Typography p={2} variant="h5" textAlign={"center"} gutterBottom>
        OES Form - Update/Edit: {formItem?.name}
      </Typography>
      <BackBtn bstyle={{ position: "fixed", left: 2, top: 2 }} />
      <LogoutButton bstyle={{ position: "fixed", top: 2, right: 2 }} />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box p={1} borderRadius={1} border={"1px solid #ddd"}>
              <Tabs value={myTab} onChange={handleTabChange}>
                <Tab label="Form Elements" />
                <Tab label="Widgets" />
              </Tabs>
              <Box height={"76vh"} overflow={"auto"}>
                {myTab === 0 ? (
                  <Box mt={1}>
                    <Droppable
                      droppableId="palette-elements"
                      isDropDisabled={true}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {paletteElements.map((element, index) => (
                            <Draggable
                              key={element.id}
                              draggableId={element.id}
                              index={index}
                            >
                              {(provided) => (
                                <Paper
                                  sx={{ p: 1, m: 1 }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Stack direction={"row"} gap={1}>
                                    {element.icon}
                                    <Typography fontSize={15}>
                                      {element.label}
                                    </Typography>
                                  </Stack>
                                </Paper>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                ) : (
                  <Box mt={1}>
                    <Droppable
                      droppableId="palette-widgets"
                      isDropDisabled={true}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {widgetElements.map((element, index) => (
                            <Draggable
                              key={element.id}
                              draggableId={element.id}
                              index={index}
                            >
                              {(provided) => (
                                <Paper
                                  sx={{ p: 1, m: 1 }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Stack direction={"row"} gap={1}>
                                    {element.icon}
                                    <Typography fontSize={15}>
                                      {element.label}
                                    </Typography>
                                  </Stack>
                                </Paper>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              height={"86vh"}
              display={"flex"}
              flexDirection={"column"}
              bgcolor={"#f4f2f3"}
              borderRadius={2}
              p={2}
            >
              <Stack direction={"row"}>
                <Box flex={1} pr={2}>
                  <FormControl sx={{ mt: 1 }} size="small" fullWidth>
                    <InputLabel>Type of form</InputLabel>
                    <Select label="Type of form">
                      {formTypes.map((item, i) => (
                        <MenuItem key={`item${i}`} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Tabs
                  sx={{ mb: 1 }}
                  value={selectedTab}
                  onChange={handleChange}
                  centered
                >
                  <Tab label="Edit/Update" />
                  <Tab label="Preview" />
                </Tabs>
              </Stack>
              <Box flex={1} overflow={"auto"}>
                {selectedTab === 0 ? (
                  <Box>
                    <Box mb={2} className="editor-quill-oes-form">
                      <Editor ref={quillRef} />
                    </Box>
                    <Droppable droppableId="form-elements">
                      {(provided) => (
                        <Grid
                          container
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            border: "1px solid grey",
                            borderRadius: 1,
                          }}
                        >
                          {formElements.length === 0 && (
                            <Typography
                              m={2}
                              variant="body1"
                              color="textSecondary"
                            >
                              Drag form fields here to build your form
                            </Typography>
                          )}
                          {formElements.map((element, index) => (
                            <Draggable
                              key={element.id}
                              draggableId={element.id}
                              index={index}
                            >
                              {(provided) => (
                                <Grid item xs={element.settings.grid}>
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    m={1}
                                    p={1}
                                    sx={{
                                      bgcolor: "#f9f9f9",
                                      borderRadius: 1,
                                      border: "1px solid #d4cfcf",
                                      position: "relative",
                                    }}
                                  >
                                    {element.type === CHECKBOX ? (
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        label={element.label}
                                      />
                                    ) : element.type === LABELLED_INPUT ? (
                                      <Labelinput label={element.label} />
                                    ) : element.type === DIVIDER ? (
                                      <Divider />
                                    ) : element.type === DYNAMIC_TABLE ? (
                                      <DynamicRowTable
                                        headers={element.settings.headers}
                                      />
                                    ) : element.type === LABEL_TEXT ? (
                                      <Typography
                                        variant={element.settings.labelOption}
                                      >
                                        {element.label}
                                      </Typography>
                                    ) : element.type === TEAM_MEMBER_LIST ? (
                                      <TeamMembers />
                                    ) : element.type === RADIO_SELECT ? (
                                      <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                          {element.label}
                                        </FormLabel>
                                        <RadioGroup
                                          aria-label="gender"
                                          name="gender"
                                          row
                                        >
                                          {element.settings.options.map(
                                            (option) => (
                                              <FormControlLabel
                                                key={option}
                                                value={option}
                                                control={
                                                  <Radio
                                                    inputProps={{
                                                      "aria-label": option,
                                                    }}
                                                  />
                                                }
                                                label={option}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    ) : element.type === PHONE ? (
                                      <MuiTelInput
                                        fullWidth
                                        size="small"
                                        label={element.label}
                                        defaultCountry="IN"
                                        forceCallingCode
                                      />
                                    ) : element.type === SELECT ? (
                                      <SelectDropDown formEl={element} />
                                    ) : element.type === MULTISELECT ? (
                                      <Autocomplete
                                        size="small"
                                        multiple
                                        id="multi-select-dropdown"
                                        options={element.settings.options}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={element.label}
                                          />
                                        )}
                                      />
                                    ) : element.type === DESCRIPTION ? (
                                      <ReactQuill
                                        theme="snow"
                                        value={value}
                                        onChange={setValue}
                                      />
                                    ) : element.type === TEXT ? (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={element.label}
                                        type={element.type}
                                        placeholder={
                                          element.settings.placeholder
                                        }
                                      />
                                    ) : element.type === DATEPICKER ? (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={element.label}
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true, // This ensures the label doesn't overlap with the date input
                                        }}
                                      />
                                    ) : element.type === TIMEPICKER ? (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={element.label}
                                        type="time"
                                        InputLabelProps={{
                                          shrink: true, // This ensures the label doesn't overlap with the date input
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <IconButton
                                      size="small"
                                      onClick={() => handleEditClick(element)}
                                      sx={{
                                        position: "absolute",
                                        top: 1,
                                        right: 1,
                                        bgcolor: "#e2e0e1", // Background color for the default state
                                        "&:hover": {
                                          bgcolor: "darkgrey", // Background color for the hover state
                                        },
                                      }}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Grid>
                      )}
                    </Droppable>

                    <Stack mt={2} direction={"row"}>
                      <Box flex={1}></Box>
                      <Button variant="contained" size="small">
                        Save
                      </Button>
                    </Stack>
                  </Box>
                ) : (
                  <OesFormPreview />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DragDropContext>
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Customize Field</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            margin="dense"
            label="Label"
            name="label"
            value={currentEditElement?.label || ""}
            onChange={(e) =>
              setCurrentEditElement({
                ...currentEditElement,
                label: e.target.value,
              })
            }
          />
          {currentEditElement?.type === LABEL_TEXT && (
            <FormControl sx={{ mt: 2 }} fullWidth size="small">
              <InputLabel id="simple-select-label">Label types</InputLabel>
              <Select
                value={currentEditElement?.settings?.labelOption || ""}
                onChange={handleEditChange}
                name="labelOption"
                labelId="simple-select-label"
                id="simple-select"
                label="Select an Option"
              >
                {currentEditElement.settings.options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {currentEditElement?.type === DYNAMIC_TABLE && (
            <AddHeaders
              onHeadersChange={handleNameValueChange}
              headers={currentEditElement?.settings.headers}
            />
          )}

          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                size="small"
                checked={currentEditElement?.settings.required || false}
                onChange={handleCheckboxChange}
                name="required"
              />
            }
            label="is Required?"
          />
          {currentEditElement?.type === SELECT && (
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  size="small"
                  checked={currentEditElement?.settings.isAutoSelect || false}
                  onChange={handleCheckboxChange}
                  name="isAutoSelect"
                />
              }
              label="is Auto Select(in auto select you can search and select)?"
            />
          )}

          {currentEditElement?.type === SELECT ||
          currentEditElement?.type === MULTISELECT ||
          currentEditElement?.type === RADIO_SELECT ? (
            <TextField
              sx={{ mt: 2 }}
              multiline
              size="small"
              fullWidth
              rows={4}
              margin="dense"
              label="Options (new line separated)"
              value={currentEditElement?.settings.options.join("\n") || ""}
              onChange={handleOptionsChange}
            />
          ) : null}
          <TextField
            sx={{ mt: 2 }}
            size="small"
            fullWidth
            label="Grid Columns (1-12)"
            name="grid"
            type="number"
            value={currentEditElement?.settings.grid || 12}
            onChange={handleEditChange}
            inputProps={{ min: 1, max: 12 }}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setEditDialogOpen(false)}>
            Cancel
          </Button>
          <Button size="small" color="error" onClick={handleDelete}>
            Delete
          </Button>
          <Button size="small" onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OesFormBuilder;
