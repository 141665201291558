import { Box } from "@mui/material";
import React from "react";
import { BadgeToPrint } from "./BadgeToPrint";

const BadgeBuilder = () => {
  return (
    <Box>
      <BadgeToPrint
        user={{ firstName: "Some name", lastName: "", companyName: "" }}
      />
    </Box>
  );
};

export default BadgeBuilder;
