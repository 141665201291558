import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const LabelKeyForm = ({ headers, onHeadersChange }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (headers) {
      setRows(headers);
    }
  }, [headers]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    onHeadersChange("headers", updatedRows);
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { label: "", key: `key${rows.length + 1}` }]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleSave = () => {
    console.log("Saved Rows:", rows);
  };

  return (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        Headers List
      </Typography>
      {rows.map((row, index) => (
        <Stack
          mb={2}
          direction={"row"}
          key={index}
          spacing={2}
          alignItems="center"
        >
          <TextField
            size="small"
            fullWidth
            label={`Header`}
            value={row.label}
            onChange={(e) => handleInputChange(index, "label", e.target.value)}
          />
          <IconButton size="small" onClick={() => handleDeleteRow(index)}>
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
        </Stack>
      ))}
      <Stack direction={"row"}>
        <Box flex={1}></Box>
        <Button
          size="small"
          variant="outlined"
          onClick={handleAddRow}
          sx={{ mb: 2 }}
        >
          Add Row
        </Button>
      </Stack>
      {/* <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleSave}
        fullWidth
      >
        Save
      </Button> */}
    </Box>
  );
};

export default LabelKeyForm;
