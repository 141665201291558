import React, { useContext } from "react";
import {
  Toolbar,
  Typography,
  Grid,
  Box,
  CardContent,
  Card,
} from "@mui/material";
import bgBanner from "../assets/gifs/ExpoBG.gif";
import { ExpoTheme } from "../themes/ExpoTheme";
import SideMenuBar from "./SideMenuBar";
import { Link, Route, Routes } from "react-router-dom";
import { AppBarHeader } from "./header/AppBarHeader";
import Dashboard from "./Dashboard";
import EmailTemplateEditor from "../emails/EmailTemplateEditor";
import EmailTemplateCards from "../emails/ChooseEmailTemplates";
import EmailBlockEditor from "../emails/EmailBlockEditor";
import ChangePasswordForm from "./profile/PasswordUpdate";
import AdminProfilePage from "./profile/AdminProfile";
import { UserContext } from "../context/UserContext";
import OesDataForm from "../formbuilder/oesform/OesDataForm";
import FormBuilder from "../formbuilder/FormBuilder";
import RegistrationSettings from "./registration/RegistrationSettings";
import RegistrationPreview from "./registration/RegistrationPreview";
import RegistrationTermsConditions from "./registration/RegistrationTermsConditions";
import RegBadgePreview from "./registration/RegBadgePreview";
import OesFormBuilder from "../formbuilder/oesform/OesFromBuilder";
import MobileAppForm from "./mobileapps/MobileAppForm";
import BadgeBuilder from "./badge/BadgeBuilder";
function MainDash() {
  const { user, loading } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarHeader open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideMenuBar open={open} setOpen={setOpen} />
      <Box
        component="main"
        position={"fixed"}
        width={"100%"}
        height={"100vh"}
        ml={7}
        sx={{
          overflowY: "auto",
          p: 3,
          transition: ExpoTheme.transitions.create("margin", {
            easing: ExpoTheme.transitions.easing.sharp,
            duration: ExpoTheme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar />
        <Box mr={7}>
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<AdminProfilePage />} />
            <Route path="change-password" element={<ChangePasswordForm />} />
            <Route path="badge-builder" element={<BadgeBuilder />} />
            <Route path="mobile-apps" element={<MobileAppForm />} />
            <Route
              path="registration-settings"
              element={<RegistrationSettings />}
            />
            <Route
              path="registration-page-preview"
              element={<RegistrationPreview />}
            />
            <Route
              path="registration-terms-conditions"
              element={<RegistrationTermsConditions />}
            />
            <Route
              path="registration-badge-preview"
              element={<RegBadgePreview />}
            />
            <Route path="oes-form-builder/:id" element={<OesFormBuilder />} />
            <Route path="email-template" element={<EmailTemplateEditor />} />
            <Route path="oes-forms" element={<OesDataForm />} />
            <Route path="registration-form-builder" element={<FormBuilder />} />
            <Route
              path="choose-email-template"
              element={<EmailTemplateCards />}
            />
            <Route path="email-block-editor" element={<EmailBlockEditor />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

export default MainDash;
