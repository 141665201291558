import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Typography,
  Grid,
  IconButton,
  TextField,
  Stack,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import DownloadIcon from "@mui/icons-material/Download";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageIcon from "@mui/icons-material/Image";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import InfoIcon from "@mui/icons-material/Info";
import { debounce } from "lodash";

// Define Block Types
const BlockType = {
  TEXT: "text",
  IMAGE: "image",
  BUTTON: "button",
};

// Sample blocks to drag with relevant icons
const blockDefinitions = [
  {
    id: 1,
    type: BlockType.TEXT,
    label: "Text Block",
    icon: <TextFieldsIcon />,
    description: "This block allows you to add text to your email.",
  },
  {
    id: 2,
    type: BlockType.IMAGE,
    label: "Image Block",
    icon: <ImageIcon />,
    description: "This block allows you to add an image to your email.",
  },
  {
    id: 3,
    type: BlockType.BUTTON,
    label: "Button Block",
    icon: <SmartButtonIcon />,
    description: "This block allows you to add a button to your email.",
  },
];
const templates = [
  {
    id: 1,
    title: "Registration Confirmation Email",
    description: "Confirm your registration with this email template.",
    body: "Dear {{name}},\n\nThank you for registering. Your account has been successfully created!",
  },
  {
    id: 2,
    title: "Password Reset Email",
    description: "Send password reset instructions to users.",
    body: "Hi {{name}},\n\nClick the link below to reset your password.\n\n{{reset_link}}",
  },
  {
    id: 3,
    title: "Forgot Password Email",
    description: "Remind users of their account details with this template.",
    body: "Dear {{name}},\n\nYou have requested to reset your password. Please follow the instructions.",
  },
  {
    id: 4,
    title: "OES Email Format",
    description: "Use this template for OES-related communications.",
    body: "Dear {{name}},\n\nThis is the official communication regarding OES.",
  },
];

// Draggable Block Component for available blocks
const DraggableBlock = ({ block, index }) => (
  <Draggable draggableId={`available-${block.type}-${index}`} index={index}>
    {(provided) => (
      <Paper
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          padding: "10px",
          cursor: "move",
          marginBottom: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ...provided.draggableProps.style,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {block.icon}
          <Typography variant="body1">{block.label}</Typography>
        </Box>
        <Tooltip title={block.description}>
          <InfoIcon color="action" />
        </Tooltip>
      </Paper>
    )}
  </Draggable>
);

// Editable Text Block
const EditableTextBlock = ({ block, index, onDelete }) => {
  const [text, setText] = useState("Edit this text...");
  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <ReactQuill value={text} onChange={setText} />
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// Editable Image Block
const EditableImageBlock = ({ block, index, onDelete }) => {
  const [imageUrl, setImageUrl] = useState("");
  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      {imageUrl ? (
        <img src={imageUrl} alt="email img" width="100%" />
      ) : (
        <Button variant="outlined" component="label">
          Upload Image
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) =>
              setImageUrl(URL.createObjectURL(e.target.files[0]))
            }
          />
        </Button>
      )}
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// Editable Button Block
const EditableButtonBlock = ({ block, index, onDelete }) => {
  const [buttonText, setButtonText] = useState("Click me");
  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <Button variant="contained" fullWidth>
        {buttonText}
      </Button>
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// Block Renderer (renders each block type in the canvas)
const BlockRenderer = ({ block, index, onDelete }) => {
  switch (block.type) {
    case BlockType.TEXT:
      return (
        <EditableTextBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.IMAGE:
      return (
        <EditableImageBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.BUTTON:
      return (
        <EditableButtonBlock block={block} index={index} onDelete={onDelete} />
      );
    default:
      return null;
  }
};

// Email Canvas with React Beautiful DnD
const EmailCanvas = ({ blocks, onDelete }) => {
  return (
    <Droppable droppableId="canvas">
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          sx={{
            padding: "20px",
            minHeight: "400px",
            backgroundColor: snapshot.isDraggingOver ? "#f0f0f0" : "#fff",
            border: "1px solid #ccc",
          }}
          {...provided.droppableProps}
        >
          <Typography variant="h6" gutterBottom>
            Email Template Builder
          </Typography>
          {blocks.length === 0 ? (
            <Typography variant="body2">
              Drag blocks from the left to add them here
            </Typography>
          ) : (
            blocks.map((block, index) => (
              <Draggable
                key={block.id}
                draggableId={`canvas-${block.id}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <BlockRenderer
                      block={block}
                      index={index}
                      onDelete={onDelete}
                    />
                  </div>
                )}
              </Draggable>
            ))
          )}
          {provided.placeholder}
        </Paper>
      )}
    </Droppable>
  );
};

// Main Component
const BlockBasedEmailTemplateBuilder = () => {
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [emailMeta, setEmailMeta] = useState({
    subject: "",
    fromEmail: "",
    fromName: "",
  });

  // Function to handle drag and drop
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // If there's no destination, do nothing
    if (!destination) return;

    // Reorder within the canvas
    if (
      source.droppableId === "canvas" &&
      destination.droppableId === "canvas"
    ) {
      const reorderedBlocks = Array.from(blocks);
      const [removed] = reorderedBlocks.splice(source.index, 1);
      reorderedBlocks.splice(destination.index, 0, removed);
      setBlocks(reorderedBlocks);
    }

    // Move from Available Blocks to Canvas
    if (
      source.droppableId === "availableBlocks" &&
      destination.droppableId === "canvas"
    ) {
      const availableBlock = blockDefinitions[source.index];
      const newBlock = { ...availableBlock, id: Date.now() }; // Generate unique ID
      const newBlocks = [...blocks, newBlock];
      setBlocks(newBlocks);
    }
  };

  const handleDeleteBlock = (index) => {
    const newBlocks = blocks.filter((_, i) => i !== index);
    setBlocks(newBlocks);
    saveHistory(newBlocks);
  };

  const saveHistory = debounce((newBlocks) => {
    setHistory((prevHistory) => [...prevHistory, blocks]);
    setRedoStack([]);
  }, 300);

  const undo = () => {
    if (history.length > 0) {
      const prevBlocks = history[history.length - 1];
      setRedoStack((prevRedo) => [...prevRedo, blocks]);
      setBlocks(prevBlocks);
      setHistory((prevHistory) => prevHistory.slice(0, -1));
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const redoBlocks = redoStack[redoStack.length - 1];
      setHistory((prevHistory) => [...prevHistory, blocks]);
      setBlocks(redoBlocks);
      setRedoStack((prevRedo) => prevRedo.slice(0, -1));
    }
  };

  const handleDownloadTemplate = () => {
    const template = {
      subject: emailMeta.subject,
      fromEmail: emailMeta.fromEmail,
      fromName: emailMeta.fromName,
      blocks,
    };
    const blob = new Blob([JSON.stringify(template, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "email_template.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Available Blocks
          </Typography>
          <Droppable droppableId="availableBlocks" isDropDisabled={true}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {blockDefinitions.map((block, index) => (
                  <DraggableBlock key={block.id} block={block} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Stack spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Tooltip
                  title={history.length === 0 ? "Nothing to undo" : "Undo"}
                >
                  <span>
                    <IconButton onClick={undo} disabled={history.length === 0}>
                      <UndoIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={redoStack.length === 0 ? "Nothing to redo" : "Redo"}
                >
                  <span>
                    <IconButton
                      onClick={redo}
                      disabled={redoStack.length === 0}
                    >
                      <RedoIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadTemplate}
              >
                Download Template
              </Button>
            </Box>
            <FormControl fullWidth size="small">
              <InputLabel>Select Template</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                label="Select Template"
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              size="small"
              label="Subject"
              value={emailMeta.subject}
              onChange={(e) =>
                setEmailMeta({ ...emailMeta, subject: e.target.value })
              }
              fullWidth
            />

            <Stack direction={"row"} gap={2}>
              <TextField
                size="small"
                label="From Name"
                value={emailMeta.fromName}
                onChange={(e) =>
                  setEmailMeta({ ...emailMeta, fromName: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="From Email"
                size="small"
                value={emailMeta.fromEmail}
                onChange={(e) =>
                  setEmailMeta({ ...emailMeta, fromEmail: e.target.value })
                }
                fullWidth
              />
            </Stack>
            <EmailCanvas blocks={blocks} onDelete={handleDeleteBlock} />
          </Stack>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};
export default BlockBasedEmailTemplateBuilder;
