import React, { useContext, useState } from "react";
import {
  Container,
  Box,
  Avatar,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  TextField,
  Stack,
  Modal,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/UserContext";
import MuPb from "../../widgets/MuPb";

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const AdminProfilePage = () => {
  const [open, setOpen] = useState(false);
  const { user, loading } = useContext(UserContext);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { register, handleSubmit, reset, watch } = useForm();
  const newPassword = watch("newPassword");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    if (data.newPassword && data.newPassword !== data.confirmPassword) {
      setError("New password and confirm password do not match");
      setSuccess("");
      return;
    }
    setError("");
    setSuccess("Profile updated successfully");
    reset();
  };

  if (loading) {
    return <MuPb />;
  }

  if (!user) {
    return <Alert severity="error">No user data found!</Alert>;
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          {/* Profile Header */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={user.profilePic}
                alt={user.name}
                sx={{ width: 80, height: 80, mr: 2 }}
              >
                {user.name.charAt(0)}
              </Avatar>
              <Box>
                <Typography variant="h5">{user.name}</Typography>
                <Typography variant="subtitle1">
                  {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                </Typography>
                <Typography variant="body2">{user.email}</Typography>
              </Box>
            </Box>
            <IconButton onClick={handleOpen}>
              <EditIcon />
            </IconButton>
          </Box>

          {/* Profile Details */}
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Full Name:</Typography>
                <Typography variant="body1">{user.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Email:</Typography>
                <Typography variant="body1">{user.email}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Phone:</Typography>
                <Typography variant="body1">{user.phone}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Role:</Typography>
                <Typography variant="body1">
                  {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Change Password Section */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    label="Current Password"
                    {...register("currentPassword", { required: true })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    label="New Password"
                    {...register("newPassword")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    type="password"
                    label="Confirm New Password"
                    {...register("confirmPassword")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" color="primary" type="submit">
                      Change Password
                    </Button>
                  </Stack>
                  {error && (
                    <Typography variant="body2" color="error" mt={1}>
                      {error}
                    </Typography>
                  )}
                  {success && (
                    <Typography variant="body2" color="primary" mt={1}>
                      {success}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>

      {/* Modal for Edit Profile */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h6" mb={2}>
            Edit Profile
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              size="small"
              fullWidth
              label="Full Name"
              defaultValue={user.name}
              {...register("fullName", { required: true })}
              sx={{ mb: 2 }}
            />
            <TextField
              size="small"
              fullWidth
              label="Email"
              type="email"
              defaultValue={user.email}
              {...register("email", { required: true })}
              sx={{ mb: 2 }}
            />
            <TextField
              size="small"
              fullWidth
              label="Phone"
              defaultValue={user.phone}
              {...register("phone")}
              sx={{ mb: 2 }}
            />
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="contained" type="submit">
                Save
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </Container>
  );
};

export default AdminProfilePage;
