import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link as RouterLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExploreIcon from "@mui/icons-material/Explore";
import GroupIcon from "@mui/icons-material/Group";
import CategoryIcon from "@mui/icons-material/Category";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MapIcon from "@mui/icons-material/Map";
import ServicesIcon from "@mui/icons-material/Build";
import BookIcon from "@mui/icons-material/Book";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import BadgeIcon from "@mui/icons-material/Badge";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import PreviewIcon from "@mui/icons-material/Preview";
import PolicyIcon from "@mui/icons-material/Policy";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import { DRAWER_WIDTH } from "../utils/constants";
import TableViewIcon from "@mui/icons-material/TableView";
import { ExpoTheme } from "../themes/ExpoTheme";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const iconMap = {
  AppSettingsAltIcon: AppSettingsAltIcon,
  SettingsIcon: SettingsIcon,
  EditIcon: EditIcon,
  DashboardIcon: DashboardIcon,
  ExploreIcon: ExploreIcon,
  GroupIcon: GroupIcon,
  CategoryIcon: CategoryIcon,
  BusinessCenterIcon: BusinessCenterIcon,
  PeopleAltIcon: PeopleAltIcon,
  MapIcon: MapIcon,
  ServicesIcon: ServicesIcon,
  BookIcon: BookIcon,
  ScheduleIcon: ScheduleIcon,
  FavoriteIcon: FavoriteIcon,
  AssessmentIcon: AssessmentIcon,
  SettingsIcon: SettingsIcon,
  LogoutIcon: LogoutIcon,
  NaturePeopleIcon: NaturePeopleIcon,
  ContactSupportIcon: ContactSupportIcon,
  TableViewIcon: TableViewIcon,
  DriveFileRenameOutlineIcon: DriveFileRenameOutlineIcon,
  BadgeIcon: BadgeIcon,
  PolicyIcon: PolicyIcon,
  PreviewIcon: PreviewIcon,
  MobileFriendlyIcon: MobileFriendlyIcon,
};
const menuItems = [
  {
    text: "Dashboard",
    link: "/home",
    icon: "DashboardIcon",
  },
  {
    text: "OES Forms",
    link: "/home/oes-forms",
    icon: "TableViewIcon",
  },

  {
    text: "Visitor Form",
    link: "#",
    icon: "ExploreIcon",
    subcategories: [
      {
        text: "Create the Form",
        link: "/home/registration-form-builder",
        icon: "DriveFileRenameOutlineIcon",
      },
      {
        text: "Settings",
        link: "/home/registration-settings",
        icon: "SettingsIcon",
      },
      {
        text: "Preview",
        link: "/home/registration-page-preview",
        icon: "PreviewIcon",
      },
      {
        text: "Terms & Conditions",
        link: "/home/registration-terms-conditions",
        icon: "PolicyIcon",
      },
      {
        text: "Badge Preview",
        link: "/home/registration-badge-preview",
        icon: "BadgeIcon",
      },
    ],
  },
  {
    text: "Floor Plan",
    link: "/home/floor-plan",
    icon: "MapIcon",
  },
  {
    text: "Exhibitors",
    link: "#",
    icon: "NaturePeopleIcon",
    subcategories: [
      {
        text: "Exhibitor List",
        link: "/home/exhibitors",
        icon: "GroupIcon",
      },
      {
        text: "Add Exhibitor",
        link: "/home/exhibitors",
        icon: "GroupIcon",
      },
    ],
  },
  {
    text: "Email templates",
    link: "/home/email-templates",
    icon: "BookIcon",
  },
  {
    text: "Badge builder",
    link: "/home/badge-builder",
    icon: "BadgeIcon",
  },
  {
    text: "Mobile Apps",
    link: "/home/mobile-apps",
    icon: "MobileFriendlyIcon",
  },
];

const SideMenuBar = ({ open, setOpen }) => {
  const [openSubmenu, setOpenSubmenu] = useState({});
  const handleClickSubmenu = (submenu) => {
    setOpenSubmenu((prevOpen) => ({
      ...prevOpen,
      [submenu]: !prevOpen[submenu],
    }));
    setOpen(true);
  };

  const renderMenuItems = (items, paddingLeft = 2) =>
    items.map((item) => (
      <React.Fragment key={item.text}>
        <ListItemButton
          onClick={() =>
            item.subcategories ? handleClickSubmenu(item.text) : null
          }
          component={item.link ? RouterLink : "button"} // Use 'button' as a fallback component
          to={item.link || ""}
          sx={{ paddingLeft }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            {iconMap[item.icon]
              ? React.createElement(iconMap[item.icon])
              : null}
          </ListItemIcon>
          <ListItemText primary={item.text} />
          {item.subcategories ? (
            openSubmenu[item.text] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </ListItemButton>
        {item.subcategories && (
          <Collapse in={openSubmenu[item.text]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderMenuItems(item.subcategories, paddingLeft + 2)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));

  useEffect(() => {
    if (!open) {
      setOpenSubmenu({}); // Close all submenus
    }
  }, [open]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? DRAWER_WIDTH : ExpoTheme.spacing(7),
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? DRAWER_WIDTH : ExpoTheme.spacing(7),
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
      open={open}
    >
      <Box
        sx={{
          height: "64px", // Default height
          display: "flex",
          alignItems: "center",
          "@media (max-width: 600px)": {
            height: "56px", // Adjusted height for smaller devices
          },
        }}
      >
        <IconButton sx={{ ml: 1 }} onClick={() => setOpen(!open)}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Collapse in={open}>
          <Box
            component="img" // Using Box as an img component
            sx={{
              ml: 2,
              maxWidth: "100%", // Ensure the image does not exceed the container's width
              height: "64px",
            }}
            src={"https://www.imtex.in/images/imtex-2025.png"}
            alt="Descriptive text for the image"
          />
        </Collapse>
      </Box>
      <Divider />
      <List>{renderMenuItems(menuItems)}</List>
    </Drawer>
  );
};
export default SideMenuBar;
