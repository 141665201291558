import React, { useState } from "react";
import { Box, TextField, Button, Typography, Grid, Paper } from "@mui/material";

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if the new password is empty
    if (!newPassword) {
      setError("New password cannot be empty");
      setSuccess("");
      return;
    }

    // Check if the new password matches the confirm password
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match");
      setSuccess("");
      return;
    }

    // Call API for changing password
    // Example: changePasswordAPI({ currentPassword, newPassword })

    setError("");
    setSuccess("Password changed successfully");
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: "500px", margin: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Change Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              required
              fullWidth
              label="Current Password"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              required
              fullWidth
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              required
              fullWidth
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>

        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color="primary" sx={{ mt: 2 }}>
            {success}
          </Typography>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
        >
          Change Password
        </Button>
      </Box>
    </Paper>
  );
};

export default ChangePasswordForm;
